import React from 'react';
import { graphql } from 'gatsby';


import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ScoutingPage from '../../components/ScoutingPage';

import '../../../node_modules/slick-carousel/slick/slick.scss';
import '../../../node_modules/slick-carousel/slick/slick-theme.scss';

class ProductScouting extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    const { data } = this.props;
    const { seo, slides, clubs, professionalsSay, intro, product, conceptBehind, workingWithPlatform, benefits, productAnalyse } = data.productScoutingDeJson;

    return (
      <Layout data={this.props.data} location={this.props.location}>
        <SEO {...seo} />

        <ScoutingPage
          headerImage={data.image.childImageSharp.fixed}
          headerImageMobile={data.imageMobile.childImageSharp.fluid}
          professionalsSay={professionalsSay}
          clubsItems={clubs}
          slidesItems={slides}
          intro={intro}
          product={product}
          conceptBehind={conceptBehind}
          workingWithPlatform={workingWithPlatform}
          benefits={benefits}
          productAnalyse={productAnalyse}
          activeLangKey={seo.lang}
        />
      </Layout>
    );
  }
}


export default ProductScouting;

export const pageQuery = graphql`
    query ProductScoutingDeQuery {
        site {
            siteMetadata {
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
        image: file(relativePath: { eq: "scouting-intro.png" }) {
            childImageSharp {
                fixed(width: 2500, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        imageMobile: file(relativePath: { eq: "scouting-intro-mobile.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        productScoutingDeJson {
            seo {
                title
                description
                lang
                keywords
            }
            clubs {
                name
                icon
            }
            slides {
                childImageSharp {
                    fixed(width: 1080, height: 720, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
            intro {
                subHeader
            }
            product {
                header
                preHeader
                contentOne
                contentTwo
            }
            conceptBehind {
                header
                contentOne
                contentTwo
            }
            workingWithPlatform {
                header
                contentOne
                contentTwo
                contentThree
            }
            benefits {
                preHeader
                header
                headerOne
                contentOne
                headerTwo
                contentTwo
                headerThree
                contentThree
                headerFour
                contentFour
                headerFive
                contentFive
                headerSix
                contentSix
                headerSeven
                contentSeven
            }
            productAnalyse {
                content
            }
            professionalsSay {
                name
                job
                msg
                image {
                    childImageSharp {
                        fixed(width: 60, height: 60, quality: 100) {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
            }
        }
    }
`;
